import React from "react";
import DashboardRoutes from "./DashboardRoutes";
import WebRoutes from "./WebRoutes";

const AppRoutes: React.FC = () => {
  return (
    <>
      <WebRoutes />
      <DashboardRoutes />
    </>
  );
};

export default AppRoutes;
