import { createSlice } from "@reduxjs/toolkit";
import { StudioProps } from "../../components/Dashboard/Home/data";
import { RootState } from "../index";
import { boolean } from "yup";

interface StudioState {
  studios: StudioProps[];
  isStudioLoading: boolean;
}

const studioState: StudioState = {
  studios: [],
  isStudioLoading: false,
};

const studioSlice = createSlice({
  name: "uploadType",
  initialState: studioState,
  reducers: {
    setAllStudios: (state, { payload }) => {
      state.studios = payload;
    },
    toggleStudioLoader: (state) => {
      state.isStudioLoading = !state.isStudioLoading;
    },
  },
});

export const audioSelector = (state: RootState) => state.audio;
export const { setAllStudios, toggleStudioLoader } = studioSlice.actions;
export default studioSlice;
