import axios, { AxiosError } from "axios";
import Echo from "laravel-echo";
import store from "../store";
import { loginFailed } from "../store/slices/authSlice";
import { BaseUrl } from "../utils/data";
import storage from "../utils/storage";
import socketio from "socket.io-client";

export const request = axios.create({
  baseURL: BaseUrl,
});

// const {
//   auth: { accessToken },
// } = store.getState();

// export const echo = new Echo({
//   host: "ws://flowbasket.com:6001",
//   broadcaster: "socket.io",
//   client: socketio,
//   authEndpoint: BaseUrl + "/broadcasting/auth",
//   auth: {
//     headers: {
//       Authorization: "Bearer " + accessToken,
//     },
//   },
// });

request.interceptors.request.use((config) => {
  const {
    auth: { accessToken },
  } = store.getState();

  const newConfig = { ...config };

  if (accessToken) {
    newConfig.headers = {
      ...newConfig.headers,
      Authorization: `Bearer ${accessToken}`,
      // "X-Socket-ID": echo.socketId(),
    };
  }

  return newConfig;
});

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    if (error.response?.status === 400) {
      return Promise.reject(error.response?.data);
    }

    if (error.response?.status === 403) {
      return Promise.reject(
        "Sorry! You're not authorized to perform this action"
      );
    }

    // if user's token has expired or has been blacklisted
    if (error.response?.status === 401 || error.response?.status === 419) {
      // logout
      store.dispatch(loginFailed());
      storage.clear();
    }
    return Promise.reject(error);
  }
);

export const api = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

api.interceptors.request.use(
  async (config: any) => {
    const {
      auth: { accessToken },
    } = store.getState();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

export default request;
