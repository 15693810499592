import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface selectedMessageProps {
  userName: string;
  userImage: string;
  userid: string;
  isBlocked?: boolean;
}

interface MessageState {
  selectedMessage?: selectedMessageProps;
}

const initialState: MessageState = {
  selectedMessage: undefined,
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setSelectedMessage: (
      state,
      action: PayloadAction<selectedMessageProps>
    ) => {
      state.selectedMessage = action.payload;
    },
  },
});

export const { setSelectedMessage } = messageSlice.actions;

export const getSelectedMessage = (state: RootState): MessageState =>
  state.message;

export default messageSlice;
