"use client";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAudioFiles, toggleLoadingState } from "./store/slices/audioSlice";
import request, { api } from "./api/request";
import { StudioProps } from "./components/Dashboard/Home/data";
import { setAllStudios, toggleStudioLoader } from "./store/slices/studioSlice";
import storage from "./utils/storage";
import { toast } from "react-toastify";

interface AppContextType {
  getAllAudios: () => void;
  getAllStudios: () => void;
  allStudios: StudioProps[];
  uploadCoverImage: (coverImage: any) => void;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

const AppProvider = ({ children }: Readonly<{ children: React.ReactNode }>) => {
  const dispatch = useDispatch();

  const [allStudios, setStudios] = useState<StudioProps[]>([]);
  const getAllAudios = async () => {
    dispatch(toggleLoadingState());
    const { data } = await request.get("/upload/files");
    dispatch(setAudioFiles(data));
    dispatch(toggleLoadingState());
  };

  const getAllStudios = async () => {
    dispatch(toggleStudioLoader());
    const { data } = await request.get("/studio");
    setStudios(data.studios);
    dispatch(setAllStudios(data.studios));
    dispatch(toggleStudioLoader());
  };

  const uploadCoverImage = async (coverImage: any) => {
    const isCoverImage = storage.get("coverImage");
    if (isCoverImage) {
      return;
    }
    if (!coverImage) {
      toast.error(`Please upload a cover iamge`);
      return;
    }

    let formData = new FormData();
    formData.append("coverImage", coverImage);
    formData.append("fileName", "coverImage");
    formData.append("type", "image");
    try {
      const { data } = await api.post("/upload", formData);
      console.log(data, data.file._id);
      return storage.set("coverImage", data.file._id);
    } catch (error: any) {
      console.log(error);
      toast.error(
        error?.response?.data?.msg || "An error occured while uploading"
      );
    }
  };

  const loadnData = () => {
    getAllAudios();
    getAllStudios();
  };

  useEffect(() => {
    loadnData();
  }, []);

  const value: AppContextType = {
    getAllAudios,
    allStudios,
    uploadCoverImage,
    getAllStudios,
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  return useContext(AppContext) as AppContextType;
};

export default AppProvider;
