import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { DashboardPaths } from "./data";
import AsyncLoader from "../helpers/AsyncLoader";
import NotFound from "../helpers/NotFound";

const DashboardRoutes = () => {
  const AuthPage = lazy(() => import("../Auth/AuthPage"));
  const DashboardLayout = lazy(
    () => import("../Dashboard/Layout/DashboardLayout")
  );
  const RequireAuth = lazy(() => import("../../hooks/RequireAuth"));
  const DashboardUploadPage = lazy(() => import("../Dashboard/Upload"));
  const StudioUploadPage = lazy(() => import("../Dashboard/Upload/AddStudio"));
  const DashboardMessagePage = lazy(() => import("../Dashboard/Messages"));
  const MessageBoxPage = lazy(() => import("../Dashboard/Messages/Messaging"));
  const ProfilePage = lazy(() => import("../Dashboard/Profile"));
  const DashboardHomePage = lazy(() => import("../Dashboard/Home"));
  const DashboardExplorePage = lazy(() => import("../Dashboard/Explore"));
  const ItemPage = lazy(() => import("../Dashboard/Explore/ItemPage"));
  const SearchPage = lazy(() => import("../Dashboard/Explore/SearchPage"));
  const StudioPage = lazy(() => import("../Dashboard/Studio"));
  const FindAProducer = lazy(() => import("../Dashboard/Studio/FindAProducer"));
  const FindAStudio = lazy(() => import("../Dashboard/Studio/FindAStudio"));
  const SingleItemPage = lazy(() => import("../Dashboard/Studio/SingleItem"));
  const DetailsPage = lazy(() => import("../Dashboard/Details"));
  const SessionPage = lazy(() => import("../Dashboard/Sessions"));
  const ActiveSessionPage = lazy(() => import("../Dashboard/Sessions/session"));

  return (
    <Routes>
      <Route
        path={DashboardPaths.Auth.SignUp}
        element={
          <Suspense fallback={<AsyncLoader />}>
            <AuthPage />
          </Suspense>
        }
      />
      <Route
        path={DashboardPaths.Auth.SignIn}
        element={
          <Suspense fallback={<AsyncLoader />}>
            <AuthPage />
          </Suspense>
        }
      />
      <Route
        path={DashboardPaths.Auth.PasswordReset}
        element={
          <Suspense fallback={<AsyncLoader />}>
            <AuthPage />
          </Suspense>
        }
      />

      <Route
        element={
          <Suspense fallback={<AsyncLoader />}>
            <DashboardLayout />
          </Suspense>
        }
      >
        <Route
          element={
            <Suspense fallback={<AsyncLoader />}>
              <RequireAuth />
            </Suspense>
          }
        >
          <Route
            path={DashboardPaths.Upload}
            element={
              <Suspense fallback={<AsyncLoader />}>
                <DashboardUploadPage />
              </Suspense>
            }
          />
          <Route
            path={DashboardPaths.Studio.UploadStudio}
            element={
              <Suspense fallback={<AsyncLoader />}>
                <StudioUploadPage />
              </Suspense>
            }
          />
          <Route
            path={DashboardPaths.Session}
            element={
              <Suspense fallback={<AsyncLoader />}>
                <SessionPage />
              </Suspense>
            }
          />
          <Route
            path={`${DashboardPaths.Session}/:id`}
            element={
              <Suspense fallback={<AsyncLoader />}>
                <ActiveSessionPage />
              </Suspense>
            }
          />

          <Route
            path={`${DashboardPaths.Messages}/:id`}
            element={
              <Suspense fallback={<AsyncLoader />}>
                <MessageBoxPage />
              </Suspense>
            }
          />

          <Route
            path={DashboardPaths.Messages}
            element={
              <Suspense fallback={<AsyncLoader />}>
                <DashboardMessagePage />
              </Suspense>
            }
          />
          <Route
            path={`${DashboardPaths.Messages}/:id`}
            element={
              <Suspense fallback={<AsyncLoader />}>
                <MessageBoxPage />
              </Suspense>
            }
          />
          <Route
            path={DashboardPaths.Profile}
            element={
              <Suspense fallback={<AsyncLoader />}>
                <ProfilePage />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={DashboardPaths.Dashboard}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <DashboardHomePage />
            </Suspense>
          }
        />
        <Route
          path={DashboardPaths.Explore}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <DashboardExplorePage />
            </Suspense>
          }
        />
        <Route
          path={`${DashboardPaths.Explore}/:genre`}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <ItemPage />
            </Suspense>
          }
        />
        <Route
          path={DashboardPaths.Search}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <SearchPage />
            </Suspense>
          }
        />
        <Route
          path={DashboardPaths.Studio.Index}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <StudioPage />
            </Suspense>
          }
        />
        <Route
          path={DashboardPaths.Studio.FindAProducer}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <FindAProducer />
            </Suspense>
          }
        />
        <Route
          path={DashboardPaths.Studio.FindAStudio}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <FindAStudio />
            </Suspense>
          }
        />
        <Route
          path={`${DashboardPaths.Studio.FindAStudio}/:id`}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <SingleItemPage />
            </Suspense>
          }
        />
        <Route
          path={`${DashboardPaths.Studio.FindAProducer}/:id`}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <SingleItemPage />
            </Suspense>
          }
        />
        <Route
          path={`${DashboardPaths.Details}/:id`}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <DetailsPage />
            </Suspense>
          }
        />
        <Route
          path={`*`}
          element={
            <Suspense fallback={<AsyncLoader />}>
              <NotFound />
            </Suspense>
          }
        />
      </Route>
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};

export default DashboardRoutes;
