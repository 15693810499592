import ScrollToTop from "./components/helpers/scrollToTop";
import AppRoutes from "./components/routes";
import AppProvider from "./context";

export const App = () => (
  <ScrollToTop>
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  </ScrollToTop>
);
