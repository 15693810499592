import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface LocationState {
  city: string;
  country: string;
}

const initialState: LocationState = {
  city: "",
  country: "",
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setUserCity: (state, action: PayloadAction<LocationState["city"]>) => {
      state.city = action.payload;
    },
    setUserCountry: (
      state,
      action: PayloadAction<LocationState["country"]>
    ) => {
      state.country = action.payload;
    },
  },
});

export const { resetAuth, setUserCity, setUserCountry } = locationSlice.actions;

export const getUserLocation = (state: RootState): LocationState =>
  state.location;

export default locationSlice;
