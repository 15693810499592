import { configureStore } from "@reduxjs/toolkit";
import audioSlice from "./slices/audioSlice";
import authSlice from "./slices/authSlice";
import locationSlice from "./slices/locationSlice";
import messageSlice from "./slices/messageSlice";
import historySlice from "./slices/historySlice";
import studioSlice from "./slices/studioSlice";

const store = configureStore({
  reducer: {
    audio: audioSlice.reducer,
    auth: authSlice.reducer,
    location: locationSlice.reducer,
    message: messageSlice.reducer,
    history: historySlice.reducer,
    studio: studioSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
