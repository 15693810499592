export const WebPaths = {
  Home: "/",
  About: "/about",
  Company: "/company",
};

export const DashboardPaths = {
  Auth: {
    SignUp: "/user/sign-up",
    SignIn: "/user/sign-in",
    PasswordReset: "/user/password-reset",
  },
  Dashboard: "/user/dashboard",
  Upload: "/user/upload",
  Messages: "/user/messages",
  Profile: "/user/profile",
  Explore: "/user/explore",
  Search: "/user/expore/search",
  Details: "/user/details",
  Session: "/user/sessions",
  Studio: {
    Index: "/user/studio",
    UploadStudio: "/user/studio/upload-studio",
    FindAProducer: "/user/studio/find-a-producer",
    FindAStudio: "/user/studio/find-a-studio",
  },
};
