import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import DrawImage from "../../assets/dashboard/undraw_feeling.svg";
import DashboardButton from "./DashboardButton";
import { useNavigate } from "react-router-dom";
import { DashboardPaths } from "../routes/data";

function NotFound() {
  const navigate = useNavigate();

  return (
    <Flex
      flexDir={"column"}
      alignItems="center"
      bg={"white"}
      py="100px"
      px={{ base: "5%", sm: "40px" }}
      minH="calc(100vh - 103px)"
      borderRadius={"baseRadius"}
    >
      <Image src={DrawImage} w="380" h="280px" />
      <Box mb="7px" mt="48px" as="h1" textStyle={"h1"} textAlign="center">
        Page Not Found
      </Box>
      <Flex
        flexDir={"column"}
        my="22px"
        gap="16px"
        width={{ base: "100%", sm: "382px" }}
      >
        <DashboardButton
          name={"Go Back Home"}
          textStyle="para2"
          variant="fullyFilled"
          borderRadius="baseRadius"
          onClick={() => {
            navigate(DashboardPaths.Dashboard, { replace: true });
          }}
        />
      </Flex>
    </Flex>
  );
}

export default NotFound;
