import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import store from "../../store";
import { theme } from "../../config";
import { HelmetProvider } from "react-helmet-async";
import { Analytics } from "@vercel/analytics/react";

const queryClient = new QueryClient();

export const AllTheProviders = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ChakraProvider theme={theme}>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_Client_ID!}
            >
              <HelmetProvider>{children}</HelmetProvider>
              <Analytics />
            </GoogleOAuthProvider>
          </ChakraProvider>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
};
