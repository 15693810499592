import { Flex, Icon, ResponsiveValue, Spinner } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

interface Props {
  variant:
    | "fullyFilled"
    | "fullyOutlined"
    | "filled"
    | "outlined"
    | "darkOutlined"
    | "redOutlined"
    | "fullyDarkFilled"
    | "disabled"
    | "fullyDisabled"
    | "submitting"
    | "fullySubmitting";
  name: string;
  textStyle: string;
  borderRadius?: string;
  icon?: IconType | undefined;
  onClick?: () => void;
  iconPosition?: "left" | "right";
  type?: "button" | "reset" | "submit";
}

const DashboardButton: React.FC<Props> = ({
  icon,
  name,
  textStyle,
  onClick,
  iconPosition,
  borderRadius,
  variant,
  type = "button",
}) => {
  const fullyFilled = {
    padding: "12px",
    flexDir:
      iconPosition === "left" ? "row" : ("row-reverse" as ResponsiveValue<any>),
    height: "44px",
    bg: "primaryColor",
    color: "white",
    // className: "btn",
    className: "material-bubble light",
  };

  const fullyDarkFilled = {
    padding: "12px",
    flexDir: "row",
    gap: "12px",
    height: "44px",
    bg: "link",
    backgroundPosition: "1% 50%",
    backgroundSize: "300% 300%",
    color: "white",
    className: "luxury",
    border: "none",
    backgroundImage:
      "linear-gradient( 45deg, transparent 0%, transparent 75%, rgba(255, 255, 255, 0.3) 75%,transparent 100%)",
  };

  const fullyOutlined = {
    padding: "12px",
    height: "44px",
    bg: "white",
    color: "primaryColor",
    outline: "2px solid #0071FF",
    className: "material-bubble",
  };

  const filled = {
    padding: "10px",
    height: "max-content",
    width: "max-content",
    bg: "#0071ff",
    color: "white",
    className: "material-bubble light",
  };

  const outlined = {
    padding: "10px",
    height: "max-content",
    width: "max-content",
    bg: "white",
    color: "primaryColor",
    outline: "2px solid #0071FF",
    className: "material-bubble",
  };
  const redOutlined = {
    padding: "10px",
    height: "max-content",
    width: "max-content",
    bg: "white",
    color: "redFF",
    outline: "2px solid #FF3C00",
  };

  const darkOutlined = {
    padding: "10px",
    height: "max-content",
    width: "max-content",
    bg: "white",
    color: "black",
    outline: "2px solid #000000",
    className: "material-bubble dark",
  };

  const fullyDisabled = {
    padding: "12px",
    height: "44px",
    flexDir:
      iconPosition === "left" ? "row" : ("row-reverse" as ResponsiveValue<any>),
    color: "white",
    bg: "#999",
    cursor: "not-allowed",
  };

  const disabled = {
    padding: "10px",
    height: "max-content",
    flexDir:
      iconPosition === "left" ? "row" : ("row-reverse" as ResponsiveValue<any>),
    width: "max-content",
    bg: "#999",
    color: "white",
    cursor: "not-allowed",
  };
  const getVaraint = () => {
    if (variant === "fullyFilled") return fullyFilled;
    if (variant === "fullyOutlined") return fullyOutlined;
    if (variant === "filled") return filled;
    if (variant === "outlined") return outlined;
    if (variant === "redOutlined") return redOutlined;
    if (variant === "darkOutlined") return darkOutlined;
    if (variant === "fullyDarkFilled") return fullyDarkFilled;
    if (variant === "disabled" || variant === "submitting") return disabled;
    if (variant === "fullyDisabled" || variant === "fullySubmitting")
      return fullyDisabled;
  };

  const getBtnVaraint = () => {
    if (variant === "fullyFilled") return "100%";
    if (variant === "fullyOutlined") return "100%";
    if (variant === "filled") return "max-content";
    if (variant === "outlined") return "max-content";
    if (variant === "redOutlined") return "max-content";
    if (variant === "darkOutlined") return "max-content";
    if (variant === "fullyDarkFilled") return "100%";
    if (variant === "disabled") return "max-content";
    if (variant === "fullyDisabled") return "100%";
    if (variant === "submitting") return "max-content";
    if (variant === "fullySubmitting") return "100%";
  };

  const isSubmitting =
    variant === "submitting" || variant === "fullySubmitting";

  return (
    <button
      type={type}
      style={{
        width: getBtnVaraint(),
      }}
      onClick={onClick}
    >
      <Flex
        alignItems={"center"}
        justifyContent="center"
        cursor={"pointer"}
        textStyle={textStyle}
        borderRadius={borderRadius}
        gap={icon ? "8px" : "0px"}
        {...getVaraint()}
      >
        {icon && !isSubmitting && <Icon as={icon} w="24px" h="24px" />}
        {isSubmitting && <Spinner size="xs" mx={"10px"} />}
        {name}
      </Flex>
    </button>
  );
};

export default DashboardButton;
