import React from "react";
import Logo from "../../assets/web/web-logo.svg";
import { Flex, Image, keyframes } from "@chakra-ui/react";
import { motion } from "framer-motion";

const animationKeyframes = keyframes`
  0% { transform: scale(0.8)  }
  50% { transform: scale(1) }
  100% { transform: scale(0.8)  }
`;

const animation = `${animationKeyframes} 1500ms ease-in-out infinite`;

const AsyncLoader: React.FC = () => {
  return (
    <Flex
      minH={"100vh"}
      w={"full"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        as={motion.div}
        animation={animation}
        w={{ base: "70%", sm: "100%" }}
      >
        <Image src={Logo} height={"100px"} alt="logo" />
      </Flex>
    </Flex>
  );
};

export default AsyncLoader;
