// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";
import "@fontsource/nunito";

// 2. Extend the theme with new layer styles
export const theme = extendTheme({
  fonts: {
    heading: "Nunito, sans-serif",
    body: "Nunito, sans-serif",
    html: "Nunito, sans-serif",
  },
  colors: {
    bg: "252525",
    primaryColor: "#0071ff",
    textLight: "#888888",
    lightModeBrand: "#4a4a4a",
    Overlay: "#d1e5ff",
    Black55: "#0000008c",
    link: "#000957",
    elevation: "#33333366",
    redFF: "#FF3C00",
    redFFD: "#FFDCD1",
    greenEB: "#EBFFEC",
    green64: "#649B60",
    greyCC: "#CCCCCC",
    greyDF: "#DFE0EB",
    greyF7: "#F7F8FC",
    greyF1: "#F1F2F4",
    blueDD: "#DDE2FF",
    black25: "#252733",
    black21: "#212121",
    black2199: "#21212199",
    black066: "#00000066",
    black08c: "#0000008C",
    skeletonStart: "#E2E8F0",
  },
  shadows: {
    shadow: "4px 0 0 22px #001aff40",
    searchShadow: "0 0 0 9px #0000002e",
    whiteNavBar: "0 -4px 0 24px #00000040",
  },
  lineHeights: {
    0: "auto",
    1: "33px",
    2: "19px",
    3: "16px",
    4: "27px",
    5: "21px",
    6: "20px",
    7: "14px",
  },
  fontSizes: {
    0: "12px",
    1: "14px",
    2: "20px",
    3: "24px",
    4: "16px",
    5: "10px",
  },
  radii: {
    baseRadius: "4px",
  },
  textStyles: {
    labelText: {
      fontSize: "4",
      fontWeight: "400",
      lineHeight: "159%",
    },
    bodyText: {
      fontSize: "1",
      fontWeight: "bold",
      lineHeight: "2",
      letterSpacing: "0",
      textDecoration: "none",
    },
    smallLink: {
      fontSize: "0",
      fontWeight: "400",
      lineHeight: "3",
      letterSpacing: "0",
      textDecoration: "none",
    },
    h1: {
      fontSize: `3`,
      fontWeight: "bold",
      lineHeight: "1",
      letterSpacing: "0",
      textDecoration: "none",
    },
    h2: {
      fontSize: "2",
      fontWeight: "bold",
      lineHeight: "4",
      letterSpacing: "0",
      textDecoration: "none",
    },
    para: {
      fontSize: "1",
      fontWeight: "500",
      lineHeight: "2",
      letterSpacing: "0",
      textDecoration: "none",
    },
    para2: {
      fontSize: "1",
      fontWeight: "400",
      lineHeight: "2",
      letterSpacing: "0",
      textDecoration: "none",
    },
    para3: {
      fontSize: "1",
      fontWeight: "600",
      lineHeight: "2",
      letterSpacing: "0",
      textDecoration: "none",
    },
    navText: {
      fontSize: "4",
      fontWeight: "400",
      lineHeight: "6",
      letterSpacing: "0.2px",
      textDecoration: "none",
    },
    littleText: {
      fontSize: "5",
      fontWeight: "400",
      lineHeight: "7",
      letterSpacing: "0",
      textDecoration: "none",
    },
    littleText2: {
      fontSize: "0",
      fontWeight: "500",
      lineHeight: "2",
      letterSpacing: "0",
      textDecoration: "none",
    },
    littleText3: {
      fontSize: "0",
      fontWeight: "bold",
      lineHeight: "3",
      letterSpacing: "0",
      textDecoration: "none",
    },
    header3: {
      fontSize: "2",
      fontWeight: "600",
      lineHeight: "4",
      letterSpacing: "0",
      textDecoration: "none",
    },
    header4: {
      fontSize: "4",
      fontWeight: "bold",
      lineHeight: "5",
      letterSpacing: "0",
      textDecoration: "none",
    },
    header5: {
      fontSize: "4",
      fontWeight: "600",
      lineHeight: "5",
      letterSpacing: "0",
      textDecoration: "none",
    },
  },
});
