import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { WebPaths } from "./data";
import AsyncLoader from "../helpers/AsyncLoader";

const WebRoutes = () => {
  const Layout = lazy(() => import("../Web/components/Layout/Layout"));
  const LandingPage = lazy(() => import("../Web/landing page"));
  const AboutUs = lazy(() => import("../Web/about us"));
  const Company = lazy(() => import("../Web/company"));

  return (
    <Suspense fallback={<AsyncLoader />}>
      <Routes>
        <Route element={<Layout />}>
          <Route path={WebPaths.Home} element={<LandingPage />} />
          <Route path={WebPaths.About} element={<AboutUs />} />
          <Route path={WebPaths.Company} element={<Company />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default WebRoutes;
