import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AudioFileProps } from "../../components/Dashboard/Home/data";
import { RootState } from "../index";

interface Props {
  isPlaying: boolean;
  activeAudioFile: extndAudioFileProps | undefined;
  isFavourite: boolean;
  audioFiles: AudioFileProps[];
  isLoading: boolean;
}

interface extndAudioFileProps extends AudioFileProps {
  playState: boolean;
}

const audioState: Props = {
  isPlaying: false,
  activeAudioFile: undefined,
  isFavourite: false,
  audioFiles: [],
  isLoading: false,
};

const audioSlice = createSlice({
  name: "uploadType",
  initialState: audioState,
  reducers: {
    setAudioPlay: (state) => {
      state.isPlaying = !state.isPlaying;
    },
    setActiveAudioFile: (state, action: PayloadAction<AudioFileProps>) => {
      state.activeAudioFile = { ...action.payload, playState: true };
    },
    setActiveAudioPlayState: (state, action: PayloadAction<boolean>) => {
      state.activeAudioFile = {
        ...(state.activeAudioFile as extndAudioFileProps),
        playState: action.payload,
      };
    },
    clearActiveAudio: (state) => {
      state.activeAudioFile = undefined;
    },
    setFavourite: (state, action: PayloadAction<boolean>) => {
      state.isFavourite = action.payload;
    },
    setAudioFiles: (state, { payload }) => {
      state.audioFiles = payload;
    },
    toggleLoadingState: (state) => {
      state.isLoading = !state.isLoading;
    },
  },
});

export const audioSelector = (state: RootState) => state.audio;
export const { setAudioFiles, toggleLoadingState } = audioSlice.actions;
export default audioSlice;
