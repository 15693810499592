import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { LoginResponse } from "../../api/auth/types";
import storage from "../../utils/storage";

interface AuthState {
  accessToken?: string;
  user?: LoginResponse;
  streamToken?: string;
}

const accessToken = storage.get("basketAccessToken");

const initialState: AuthState = {
  accessToken: accessToken,
  streamToken: "",
  user: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setToken: (state, { payload }) => {
      state.accessToken = payload.token;
      state.streamToken = payload.streamToken;
    },
    loginFailed: (state): any => {
      state.user = undefined;
      state.accessToken = undefined;
    },
  },
});

export const { resetAuth, setUser, loginFailed, setToken } = authSlice.actions;

export const getAuth = (state: RootState): AuthState => state.auth;

export default authSlice;
