import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { AudioFileProps } from "../../components/Dashboard/Home/data";

export const historyStorageKey = "playing_history";

export interface HistoryProps {
  history: AudioFileProps[];
}

interface HistoryState {
  playingHistory?: HistoryProps;
}

const initialState: HistoryState = {
  playingHistory: undefined,
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    setPlayingHistory: (state, action: PayloadAction<HistoryProps>) => {
      state.playingHistory = action.payload;
    },
  },
});

export const { setPlayingHistory } = historySlice.actions;

export const getPlayingHistory = (state: RootState): HistoryState =>
  state.history;

export default historySlice;
